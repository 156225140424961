
.background-wrapper {
  width: 100vw;
  height: 100vw;
  background: linear-gradient(180deg, #94bfd9, #ffffff) !important;
}

.conference-item {
  text-decoration: none;
  color: #000;
  max-width: 200px;
  border: none;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 18%) !important;

  .card-body {
    font-size: 0.9rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    .card-title {
      line-height: 1rem;
    }
  }

  .person-image {
    object-fit: cover;
    object-position: top;
    aspect-ratio: 1/1;
    min-width: 50px;
  }
}

@media(max-width: 767px) {
  .conference-item {
    max-width: 100%;
    border-radius: 0;
    border: none;
    box-shadow: none !important;
  }
}
$conf-color: #10528E;
#branding-wrapper {
  color: #fff;
  //background: url(../img/splash-bg.jpg) no-repeat center center fixed;
  background-color: $conf-color;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  --background: -moz-linear-gradient(180deg, $conf-color 0%, $conf-color 50%, rgba(109, 140, 162, 0.4) 100%); /* FF3.6-15 */
  --background: -webkit-linear-gradient(180deg, $conf-color 0%, $conf-color 50%, rgba(109, 140, 162, 0.4) 100%); /* Chrome10-25,Safari5.1-6 */
  --background: linear-gradient(180deg, $conf-color 0%, $conf-color 50%, rgba(109, 140, 162, 0.4) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#333F6A88', endColorstr='#336D8CA2', GradientType=1); /* IE6-9 fallback on horizontal gradient */


  .branding-intro {
    width: 100%;
    max-width: 600px;
    max-height: 30px;
  }

  #branding-intro-logo-image path {

  }

  #branding-intro-logo-text path {
    stroke: #fff;
    stroke-dasharray: 2200;
    stroke-dashoffset: 1000;
    animation: dash-text 2s ease-out forwards 1;
    transition: fill .2s ease-out;
  }
}
